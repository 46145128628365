@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="text"] {
  @apply w-full;
  @apply p-sm rounded-md;
  @apply bg-gray-100 border-0; 
  @apply focus:bg-indigo-50 focus:outline-none focus:ring-0;
}
